import { useEffect, useState } from "react";
import "../Styles/Slideshow.css";

const Slideshow = ({ content, device, media }) => {
  const tags = media ? media.split(",") : [];
  var [clickedPosition, setClicked] = useState(0);
  const contentArr = content
    ? content.split(",")
    : ["./empty.png", "./empty.png", "./empty.png"];
  const image = device ? device + "-image" : "mobile-image";
  const container = device ? device + "-container" : "mobile-container";
  const [bigImage, setImage] = useState();
  const [isImage, setIsImage] = useState(true);

  useEffect(() => {
    content ? setImage(contentArr[0]) : setImage("./empty.png");
    setClicked(0);
    setIsImage("image");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const handelClick = (index, tag) => {
    setClicked(index);
    content ? setImage(contentArr[index]) : setImage();
    if (tag === "image") {
      setIsImage(true);
    } else {
      setIsImage(false);
    }
  };

  return (
    <div className={"row-container"}>
      <div className={container}>
        {isImage ? (
          <img
            alt="a Alec Hallman Website"
            src={bigImage}
            className={"lg-" + image}
          ></img>
        ) : (
          <div className="video-container">
            <iframe
              className="small-video"
              src={bigImage}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </div>
      <div className={container}>
        {contentArr.map((link, index) => {
          return (
            <>
              <div
                className={
                  index === clickedPosition
                    ? "clicked-crop-image"
                    : "unclicked-crop-image"
                }
              >
                {tags.length !== 0 ? (
                  tags[index] === "image" ? (
                    <img
                      onClick={() => {
                        handelClick(index, "image");
                      }}
                      alt="Alec Hallman Website Software Developer"
                      src={link}
                      className={"sm-" + image}
                    ></img>
                  ) : (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          zIndex: 1,
                          backgroundColor: "rgba(0, 0, 0, 0)",
                        }}
                        onClick={() => {
                          handelClick(index, "video");
                        }}
                      ></div>
                      <iframe
                        width="100%"
                        height="100%"
                        src={link}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  )
                ) : (
                  <img
                    onClick={() => {
                      handelClick(index, "image");
                    }}
                    alt="Alec Hallman Computer Science"
                    src={link}
                    className={"sm-" + image}
                  ></img>
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};
export default Slideshow;
