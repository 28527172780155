import "../Styles/Filmloading.css";
const Filmloading = ({ loaded }) => {
  return (
    <>
      <div className="parent-container">
        <img
          alt="Alec Hallman Web Developer clapper"
          src="Film/clapper.png"
          className={loaded ? "loaded-clapper" : "clapper"}
        ></img>
        <img
          alt="Alec Hallman Full Stack Developer bottom clapper"
          src="./Film/bottom.png"
          className={loaded ? "loaded-base" : "base"}
        ></img>
      </div>
    </>
  );
};
export default Filmloading;
