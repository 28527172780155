import { useEffect, useState } from "react";
import "../Styles/HomeSlideshow.css";
const FloatingWindow = ({ text }) => {
  const [mousePosition, setPosition] = useState({ x: 0, y: 0 });
  const handleMove = (event) => {
    setPosition({ x: event.clientX + 10, y: event.clientY + 80 });
  };
  useEffect(() => {
    window.addEventListener("mousemove", handleMove);
    return () => {
      window.removeEventListener("mousemove", handleMove);
    };
  }, []);
  return (
    <div
      className="floating-description"
      style={{ left: mousePosition.x, top: mousePosition.y }}
    >
      <p>{text}</p>
    </div>
  );
};
export default FloatingWindow;
