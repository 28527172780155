const Software = ({ software, setClass, media }) => {
  const softwareArr = software.split(",");
  return (
    <div>
      {softwareArr.map((software, index) => {
        return (
          <img
            key={index}
            src={"icons/" + software + ".png"}
            alt={software + "Alec Hallman Website"}
            className={setClass}
          ></img>
        );
      })}
    </div>
  );
};
export default Software;
