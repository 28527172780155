import { useEffect, useRef, useState } from "react";
import "../Styles/Scrollbar.css";
const Scrollbar = ({ images, imageClick }) => {
  const scrollContainerRef = useRef(null);
  const [clcikedIndex, setClicked] = useState(0);
  const imageClicked = (index) => {
    imageClick(index);
    setClicked(index);
  };
  const handleClick = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      if (direction === "right") {
        container.scrollLeft += 200;
      } else {
        container.scrollLeft -= 200;
      }
    }
  };
  useEffect(() => {
    const container = scrollContainerRef.current;
    const handleScroll = (event) => {
      if (container) {
        if (event.deltaY > 0) {
          //right
          container.scrollLeft += 200;
        } else {
          //left
          container.scrollLeft -= 200;
        }
      }
    };

    if (container) {
      container.addEventListener("wheel", handleScroll);
    }
    return () => {
      container.removeEventListener("wheel", handleScroll);
    };
  });
  return (
    <div className="row">
      <img
        src="./icons/LeftArrow.png"
        alt="Alec Hallman Portfolio Left Arrow"
        className="arrow-btn"
        onClick={() => {
          handleClick("left");
        }}
      ></img>
      <div className="image-row" ref={scrollContainerRef}>
        {images.map((image, index) => {
          return (
            <div className={index === clcikedIndex ? "clicked" : "unclicked"}>
              <img
                className="image"
                src="./Film/Filmroll.png"
                alt="Alec Hallman Website filmroll"
                onClick={() => {
                  imageClicked(index);
                }}
              ></img>
              <div className="roll-txt">{image.name}</div>
            </div>
          );
        })}
      </div>
      <img
        className="arrow-btn"
        onClick={() => {
          handleClick("right");
        }}
        src="./icons/RightArrow.png"
        alt="right arrow Alec Hallman Software Developer"
      ></img>
    </div>
  );
};
export default Scrollbar;
