import { useEffect, useRef, useState } from "react";
import "../Styles/audioDescription.css";
const Audiodescription = ({ description, link }) => {
  const playerRef = useRef(null);
  const [videoLength, setDuration] = useState("0:00");
  const [currentTime, setCurrentTime] = useState("0:00");
  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    let intervalId;
    const AudioReady = () => {
      setPlaying(false);
      playerRef.current = new window.YT.Player("player", {
        height: "0",
        width: "0",
        videoId: link,
        events: {
          onReady: (event) => {
            const duration = event.target.getDuration();
            setDuration(formatDuration(duration));
            setInterval(() => {
              if (playerRef.current && playerRef.current.getCurrentTime) {
                const currentTime = playerRef.current.getCurrentTime();
                const formattedDuration = formatDuration(currentTime);
                setCurrentTime(formattedDuration);
              }
            }, 1000);
            playerRef.current.setVolume(100);
          },
        },
      });
    };
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      window.onYouTubeIframeAPIReady = AudioReady;
      document.head.appendChild(tag);
    } else {
      AudioReady();
    }
    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [link]);
  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const remainingSeconds = Math.floor(duration % 60);
    return remainingSeconds < 10
      ? minutes + ":0" + remainingSeconds
      : minutes + ":" + remainingSeconds;
  };
  const playVideo = () => {
    if (playerRef.current) {
      if (playing && playerRef.current.pauseVideo) {
        playerRef.current.pauseVideo();
        setPlaying(false);
      } else if (playerRef.current.playVideo) {
        playerRef.current.playVideo();
        setPlaying(true);
      }
    }
  };
  return (
    <div className="entire-audio-container">
      <div className="desc-container">
        <p className="description-txt">{description}</p>
      </div>
      <div className="playbackbar-container">
        <p className="playback-txt">{currentTime}</p>

        <img
          className="media-btn"
          title="Want to hear me read this aloud?"
          src={playing === true ? "./icons/Pause.png" : "./icons/Play.png"}
          alt="Alec Hallman mediaControlImage"
          onClick={() => {
            playVideo();
          }}
        ></img>
        <p className="playback-txt">{videoLength}</p>
      </div>
      <div id="player" style={{ display: "none" }}></div>
    </div>
  );
};
export default Audiodescription;
